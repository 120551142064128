import { h, render } from 'preact';
import Widget from './widget';
import {defaultConfiguration} from './default-configuration';

if (window.attachEvent) {
    window.attachEvent('onload', injectChat);
} else {
    window.addEventListener('load', injectChat, false);
}

function injectChat() {
    let root = document.createElement('div');
    root.id = 'intergramRoot';
    document.getElementsByTagName('body')[0].appendChild(root);
    const iFrameSrc = '/chat';
    const host = window.location.host || 'unknown-host';
    const conf = { ...defaultConfiguration, ...window.intergramCustomizations };

    render(
        <Widget host={host}
                isMobile={window.screen.width < 500}
                iFrameSrc={iFrameSrc}
                conf={conf}
        />,
        root
    );

    try {
        const request = new XMLHttpRequest();
        request.open('POST', '/usage-start?host=' + host);
        request.send();
    } catch (e) { /* Fail silently */ }

}
